<template>
  <div>
    <CCard>
      <CCardHeader>Create New Cashtag</CCardHeader>
      <CCardBody>
        <CRow>
          <CCol md="12" lg="6">
            <CForm @submit="submitForm">
              <div class="form-group vmselect" v-bind:class="{ valid: validateInput(sitesInput.val, 'sites'), invalid: !validateInput(sitesInput.val, 'sites') }">
                <multiselect v-model="selectedSites" tag-placeholder="Add this as new tag" placeholder="Search or add a tag" label="name" track-by="code" :options="siteOptions" :multiple="true" :taggable="true" :show-labels="false" :custom-label="customLabel"></multiselect>
                <div class='invalid-feedback'>{{ invalidFeedback('sites') }}</div>
              </div>
              <CInput required placeholder='Name on account' v-model='nameInput.val' v-bind:class="{ valid: validateInput(nameInput.val, 'name') }" :invalidFeedback="invalidFeedback('name')" :isValid="validateInput(nameInput.val, 'name')">
                <template #prepend-content><CIcon name='cil-user'/></template>
              </CInput>
              <CInput required placeholder='Cashtag' v-model='cashtagInput.val' v-bind:class="{ valid: validateInput(cashtagInput.val, 'cashtag') }" :invalidFeedback="invalidFeedback('cashtag')" :isValid="validateInput(cashtagInput.val, 'cashtag')">
                <template #prepend-content><CIcon name='cil-dollar'/></template>
              </CInput>
              <CInput required placeholder='Mobile number' v-model='mobileInput.val' v-bind:class="{ valid: validateInput(mobileInput.val, 'mobile') }" :invalidFeedback="invalidFeedback('mobile')" :isValid="validateInput(mobileInput.val, 'mobile')">
                <template #prepend-content><CIcon name='cil-phone'/></template>
              </CInput>
              <CInput placeholder='Email address' v-model='emailInput.val' v-bind:class="{ valid: validateInput(emailInput.val, 'email') }" :invalidFeedback="invalidFeedback('email')" :isValid="validateInput(emailInput.val, 'email')">
                <template #prepend-content><CIcon name='cil-envelope-closed'/></template>
              </CInput>
              <div class="form-group form-actions">
                <CButton block type='submit' color='success' :disabled='formLoading'>
                  <span class='spinner-border spinner-border-sm' role='status' aria-hidden='true' v-if='formLoading'></span>
                  Create
                </CButton>
              </div>
            </CForm>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import apiCashtag from '@/api/cashtag'
import apiSite from '@/api/site'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
  name: 'CreateCashtag',
  components: {
    Multiselect
  },
  watch: {
    'selectedSites': function() {
      this.sitesInput.val = []
      this.selectedSites.forEach((site) => {
        this.sitesInput.val.push(site.code)
      })
    }
  },
  data () {
    return {
      formLoading: false,
      nameInput: { val: null, serverErrMsg: null , serverErrVal: null },
      cashtagInput: { val: null, serverErrMsg: null , serverErrVal: null },
      mobileInput: { val: null, serverErrMsg: null , serverErrVal: null },
      emailInput: { val: null, serverErrMsg: null , serverErrVal: null },
      sitesInput: { val: [], serverErrMsg: null , serverErrVal: [] },
      selectedSites: [],
      siteOptions: []
    }
  },
  created() {
    apiSite.list(1, 200, null, null, {status:0}).then(response => {
      this.siteOptions = response.data.data
      if(this.siteOptions.length > 0){
        this.selectedSites.push(this.siteOptions[0])
      }
    }).catch((error) => {
      this.$toast.error('Error loading list.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
    })
  },
  methods: {
    customLabel ({ name, domain, code }) {
      return `${code} – ${name} (${domain})`
    },
    validateInput (val, field) {
      if(val === null){
      }else{
        if(field == 'name'){ // alphanumeric whitespace allowed
          return (val && /^[a-zA-Z0-9\s]+$/.test(val) && val !== this.nameInput.serverErrVal) ? true : false
        }
        if(field == 'cashtag'){ // alphanumeric whitespace not allowed
          return (val && /^[a-zA-Z0-9]+$/.test(val) && val !== this.cashtagInput.serverErrVal) ? true : false
        }
        if(field == 'mobile'){ // numeric length 10
          return (val && val.length == 10 && /^[0-9]+$/.test(val) && val !== this.mobileInput.serverErrVal) ? true : false
        }
        if(field == 'email'){ // valid email (optional)
          return (val && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(val) && val !== this.emailInput.serverErrVal) ? true : false
        }
        if(field == 'sites'){ // valid sites
          var is_same = (val.length == this.sitesInput.serverErrVal.length) && val.every((element, index) => {
            return element === this.sitesInput.serverErrVal[index]; 
          });
          return (val && val.length > 0 && !is_same) ? true : false
        }
      }
    },
    invalidFeedback (field) {
      if(field == 'name'){
        return (this.nameInput.val !== this.nameInput.serverErrVal) ? 'Alphanumeric characters and spaces only' : this.nameInput.serverErrMsg
      }
      if(field == 'cashtag'){
        return (this.cashtagInput.val !== this.cashtagInput.serverErrVal) ? 'Alphanumeric characters only' : this.cashtagInput.serverErrMsg
      }
      if(field == 'mobile'){
        return (this.mobileInput.val !== this.mobileInput.serverErrVal) ? '10 numeric characters only' : this.mobileInput.serverErrMsg
      }
      if(field == 'email'){
        return (this.emailInput.val !== this.emailInput.serverErrVal) ? 'Invalid email address' : this.emailInput.serverErrMsg
      }
      if(field == 'sites'){
        var is_same = (this.sitesInput.val.length == this.sitesInput.serverErrVal.length) && this.sitesInput.val.every((element, index) => {
          return element === this.sitesInput.serverErrVal[index]; 
        });
        return !this.sitesInput.serverErrMsg ? 'Invalid site(s) selected' : this.sitesInput.serverErrMsg
      }
    },
    submitForm: function (e) {
      
      e.preventDefault()

      var emailValidated = false
      if(this.emailInput.val === null || this.emailInput.val === ''){
        emailValidated = true
      }else{
        if(this.validateInput(this.emailInput.val, 'email')){
          emailValidated = true
        }
      }
      
      if(this.validateInput(this.nameInput.val, 'name') && this.validateInput(this.cashtagInput.val, 'cashtag') && this.validateInput(this.mobileInput.val, 'mobile') && emailValidated && this.validateInput(this.sitesInput.val, 'sites')){

        this.formLoading = true
        apiCashtag.create(this.nameInput.val, this.cashtagInput.val, this.mobileInput.val, this.emailInput.val, this.sitesInput.val).then(response => {
          this.formLoading = false
          this.$toast.success('Successfully created new cashtag.', { position: 'top-right', timeout: 3000, hideProgressBar: true })
          this.$router.go(-1) 
        }).catch((err) => {
          this.formLoading = false
          if(err.response.data.status == 'validation.error' && err.response.data.errors){
            err.response.data.errors.forEach((error) => {
              if(error.field == 'name'){
                this.nameInput.serverErrVal = this.nameInput.val
                this.nameInput.serverErrMsg = error.message
              }
              if(error.field == 'cashtag'){
                this.cashtagInput.serverErrVal = this.cashtagInput.val
                this.cashtagInput.serverErrMsg = error.message
              }
              if(error.field == 'mobile'){
                this.mobileInput.serverErrVal = this.mobileInput.val
                this.mobileInput.serverErrMsg = error.message
              }
              if(error.field == 'email'){
                this.emailInput.serverErrVal = this.emailInput.val
                this.emailInput.serverErrMsg = error.message
              }
              if(error.field == 'sites'){
                this.sitesInput.serverErrVal = this.sitesInput.val
                this.sitesInput.serverErrMsg = error.message
              }
            });
          }
          this.$toast.error('Failed to create new cashtag.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
        })

      }

    }
  }
}
</script>
